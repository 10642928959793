import { Col, Dropdown, Menu, Row, Space, Tooltip, Button } from 'antd'
import React, { useState } from 'react'
import downloadImg from 'assets/Images/Download.svg'
import { DownOutlined } from '@ant-design/icons'
import ExportPayment from './ExportPayment'
import { AddCustomer } from './AddCustomer'
export const Header = ({ data }: any) => {
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCustomerClose = () => {
    setIsModalVisible(false)
  }
  const menu = (
    <Menu>
      <Menu.Item>
        <Space onClick={handleExportClick}>
          <img alt="img" src={downloadImg} /> Export
        </Space>
      </Menu.Item>
    </Menu>
  )
  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  return (
    <Row className="page-header" justify="space-between">
      <Col lg={{ span: 6 }} xs={{ span: 24 }}>
        <h1 className="table-head mb-0">Payment history</h1>
      </Col>
      <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
        <div className="right-search-block">
          <div
            className="table-action-btn new-organizations-export-btn"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Dropdown
              overlay={menu}
              placement="bottom"
              className="bg-grey mx-20px"
            >
              <button className=" prim-sm-btn" style={{ color: 'black' }}>
                More <DownOutlined />
              </button>
            </Dropdown>
            <Tooltip placement="bottom" title="Add">
              <Button className={'btn-new-activity  br-8'} onClick={showModal}>
                Add Customer ID
              </Button>
            </Tooltip>
          </div>
        </div>
      </Col>
      <ExportPayment
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={data?.records}
      />
      <AddCustomer visible={isModalVisible} onClose={handleCustomerClose} />
    </Row>
  )
}
export default Header
