import React, { useState } from 'react'
import { Button, Modal, Select, Input, Space } from 'antd'
import Cancel from 'assets/Images/close.svg'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { AddFamilyCustomer } from 'api/family'
export const AddCustomer = ({ visible, onClose }: any) => {
  const { id }: { id: string } = useParams()
  const [customerId, setCustomerId] = useState('')
  const handleAdd = async () => {
    changeMutation.mutate({
      familyId: id,
      customerId: customerId,
    })
  }
  const changeMutation = useMutation({
    mutationFn: async ({ familyId, customerId }: any) => {
      return await AddFamilyCustomer(familyId, customerId)
    },
    onSuccess: resp => {
      if (resp) {
        // newsRefetch()
        onClose()
        setCustomerId('')
      }
    },
  })
  return (
    <Modal visible={visible} onCancel={onClose} footer={null}>
      <div className="popup-header">
        <h2 className="importText ">
          <b>Add Customer ID</b>
        </h2>
        <img src={Cancel} alt="close button" onClick={onClose} />
      </div>
      {/* <p className="Note-text">Add stripe customers to the family.</p> */}
      <p className="reason-text mt-20">Add Customer ID:</p>

      <Input
        className="mt-10"
        placeholder="Enter Customer ID"
        value={customerId}
        onChange={e => setCustomerId(e.target.value)}
      />
      <div className="flex justify-end w-full mt-20">
        <Space size={20}>
          {/* <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={onClose}
          >
            Cancel
          </button> */}
          {/* onClick={handleUnpublish} */}
          <button className="prim-sm-btn" onClick={handleAdd}>
            Save
          </button>
        </Space>
      </div>
    </Modal>
  )
}
