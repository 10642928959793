// import { Row, Col, Dropdown, Tooltip, Button, List, Avatar } from 'antd'
// import React from 'react'
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
// import StripIcon from './../../../../../assets/Images/stripe.svg'
// import delectIcon from './../../../../../assets/Images/Trash.svg'
// const customers = Array(10).fill({ email: 'example@gmail.com' })

// export const ExistingCustomer = () => {
//   return (
//     <>
//       <Row className="page-header" justify="space-between">
//         <Col lg={{ span: 6 }} xs={{ span: 24 }}>
//           <h1 className="table-head mb-0">Existing customer ID </h1>
//         </Col>
//         <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
//           <div className="right-search-block">
//             <div
//               className="table-action-btn new-organizations-export-btn"
//               style={{
//                 display: 'flex',
//                 justifyContent: 'flex-end',
//                 width: '100%',
//               }}
//             >
//               <Tooltip placement="bottom" title="Add">
//                 <Button className={'btn-new-activity  br-8'}>
//                   Add Customer ID
//                 </Button>
//               </Tooltip>
//             </div>
//           </div>
//         </Col>
//       </Row>
//       <Row className="page-header">
//         <Col>
//           <List
//             style={{ borderBottom: '1px solid grey' }}
//             itemLayout="horizontal"
//             dataSource={customers}
//             renderItem={item => (
//               <List.Item
//                 actions={[
//                   //   <Button icon={<EditOutlined />} type="text" />,
//                   <Button type="text" danger>
//                     {' '}
//                     <img src={delectIcon} alt="Delete"></img>
//                   </Button>,
//                 ]}
//               >
//                 <List.Item.Meta
//                   avatar={<img src={StripIcon} />}
//                   title={item.email}
//                 />
//               </List.Item>
//             )}
//           />
//         </Col>
//       </Row>
//     </>

//   )
// }

import { Row, Col, Tooltip, Button, List } from 'antd'
import React from 'react'
import StripIcon from './../../../../../assets/Images/stripe.svg'
import delectIcon from './../../../../../assets/Images/Trash.svg'

const customers = Array(10).fill({ email: 'example@gmail.com' })

export const ExistingCustomer = () => {
  return (
    <>
      <Row className="page-header" justify="space-between">
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <h1 className="table-head mb-0">Existing customer ID </h1>
        </Col>
        <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
          <div className="right-search-block">
            <div
              className="table-action-btn new-organizations-export-btn"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {/* <Tooltip placement="bottom" title="Add">
                <Button className={'btn-new-activity br-8'}>
                  Add Customer ID
                </Button>
              </Tooltip> */}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="page-header">
        <Col>
          <List
            itemLayout="horizontal"
            dataSource={customers}
            renderItem={item => (
              <List.Item
                style={{ borderBottom: '1px solid #EBEDF1' }}
                actions={[
                  // Use the delectIcon image inside an <img> tag
                  <Button type="text" danger>
                    <img
                      src={delectIcon}
                      alt="Delete"
                      style={{ width: 20, height: 20 }}
                    />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<img src={StripIcon} alt="Stripe Icon" />}
                  title={<div>{item.email}</div>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  )
}
